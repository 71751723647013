import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout.js"
import Seo from "../components/seo.js"

const ErrorPage = ({ data }) => {

  return (
        <Layout>
        <Seo
          pagetitle="ページが見つかりません"
        />
        <section className="hero">
          <figure>
          <img src="/images/hero.jpg" alt="" />
          </figure>
          <div className="catch">
            <h1>ページが見つかりませんでした</h1>
            <p>指定されたページは、このサーバ上に存在しません。<br />
他の場所に移動したか、または削除された可能性があります。<br />
ご指定のURLにタイプミスがないかご確認ください。<br />
<br />
上のメニューから目的のカテゴリを選んで移動するか、<br />
以下のURLからESSENTIALS WEBサイトのトップページへ移動出来ます。<br />
<br />
<Link to={`/`}>https://quizzical-mirzakhani-8edfa4.netlify.app/</Link></p>
          </div>
        </section>
        </Layout>
  )
}

export default ErrorPage
